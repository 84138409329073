import { FC } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { ProgressFilterOptions, ProgressFilterValue } from '../../../../models/TableViewFilters';
import { useTranslation } from 'react-i18next';
import Badge from '../../../shared/badge/Badge';

const ProgressFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const progressFilter = filter as ProgressFilterValue | undefined;
  const { t } = useTranslation(['table-view', 'common']);

  return (
    <div>
      {progressFilter && (
        <div className="flex items-center gap-2">
          {progressFilter.selectedOption === ProgressFilterOptions.EXACT && (
            <Badge textClass="text-dpm-gray-2 !font-normal" text={`${progressFilter.exact} %`} />
          )}
          {progressFilter.selectedOption === ProgressFilterOptions.BETWEEN && (
            <Badge
              textClass="text-dpm-gray-2 !font-normal"
              text={t('filters.progress.combined-between', {
                option: t(`filters.progress.${progressFilter.selectedOption}`),
                start: progressFilter.between?.start ?? 0,
                end: progressFilter.between?.end ?? 0,
              })}
            />
          )}
          {progressFilter.selectedOption === ProgressFilterOptions.LESS_THAN && (
            <>
              <Badge
                textClass="text-dpm-gray-2 !font-normal"
                text={t('filters.progress.combined-interval', {
                  option: t(`filters.progress.${progressFilter.selectedOption}`),
                  value: progressFilter.lessThan ?? 0,
                })}
              />
            </>
          )}
          {progressFilter.selectedOption === ProgressFilterOptions.MORE_THAN && (
            <>
              <Badge
                textClass="text-dpm-gray-2 !font-normal"
                text={t('filters.progress.combined-interval', {
                  option: t(`filters.progress.${progressFilter.selectedOption}`),
                  value: progressFilter.moreThan ?? 0,
                })}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProgressFilterOverview;
